import { ReactElement } from 'react'

import { displayToast } from 'components/Toast'
import ToastCard, { toastOptions } from 'components/ToastCard'
import DropdownContextMenu from 'components/dropdowns/Dropdown/DropdownContextMenu'

import {
  BookmarkFolderPartsFragment,
  BookmarkType,
  CourseBookmarkPartsFragment,
  EventBookmarkPartsFragment,
  ProgramBookmarkPartsFragment
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { onEnterKeyPress } from 'utils/keyboard'
import { trackBookmarkAction } from 'utils/tracking/analytics'

const ADD_NOTE_TYPES = [
  BookmarkType.TEXTBOOKMARK,
  BookmarkType.IMAGEBOOKMARK,
  BookmarkType.VIDEOBOOKMARK
]
interface BookmarkControlsProps {
  bookmark: ProgramBookmarkPartsFragment | CourseBookmarkPartsFragment
  onDeleteBookmark: () => void
  onEditBookmark?: (
    editedBookmark: ProgramBookmarkPartsFragment | CourseBookmarkPartsFragment
  ) => void
  restoreBookmark?: (
    deletedBookmark: ProgramBookmarkPartsFragment | CourseBookmarkPartsFragment
  ) => void
  setShowNoteElement?: (show: boolean) => void
  onAddNote?: () => void
  controlType?: string
  bookmarkFolders?: BookmarkFolderPartsFragment[]
  currentFolder?: BookmarkFolderPartsFragment | null
  openAddToBookmarkFolderModal?: (
    bookmark:
      | ProgramBookmarkPartsFragment
      | CourseBookmarkPartsFragment
      | EventBookmarkPartsFragment
  ) => void
  handleRemoveFromFolder?: (
    bookmarkId: string,
    bookmarkFolder: BookmarkFolderPartsFragment
  ) => Promise<string | null | undefined>
  handleEditBookmark?: () => void
  triggerElementOverride?: ReactElement
}

const BookmarkControls = ({
  bookmark,
  onDeleteBookmark,
  onEditBookmark,
  restoreBookmark,
  onAddNote,
  currentFolder,
  openAddToBookmarkFolderModal,
  handleRemoveFromFolder,
  handleEditBookmark,
  triggerElementOverride
}: BookmarkControlsProps) => {
  const { currentUser } = useCurrentUser()
  const showSaveToCollection =
    currentUser?.is.member || currentUser?.is.paidMember || currentUser?.is.planManager

  const handleDeleteBookmark = () => {
    onDeleteBookmark()
    displayToast(
      <ToastCard
        type="destructive"
        message="Your highlight has been deleted"
        onUndo={() => restoreBookmark?.(bookmark)}
      />,
      toastOptions
    )
  }

  const deleteNoteBody = () => {
    trackBookmarkAction({ action: 'delete_note', component: 'action_menu' })
    onEditBookmark?.({ ...bookmark, noteBody: '' })
  }

  const bookmarkFolderDropdown = () => {
    // To be used for when there are no folders
    // const hasFolder = bookmarkFolders && bookmarkFolders?.length > 0
    return (
      <>
        {currentFolder && (
          <DropdownContextMenu.DropdownItem
            text={
              currentFolder?.name === 'My Saved Items'
                ? 'Remove from Saved Items'
                : 'Remove from Collection'
            }
            onClick={() => {
              handleRemoveFromFolder?.(bookmark.id, currentFolder)
            }}
          />
        )}
        {(!currentFolder || currentFolder?.name === 'My Saved Items') &&
          showSaveToCollection && (
            <DropdownContextMenu.DropdownItem
              text={'Save To Collection'}
              onClick={() => {
                openAddToBookmarkFolderModal?.(bookmark)
              }}
            />
          )}
      </>
    )
  }

  const deleteBookmark = (
    <DropdownContextMenu.DropdownItem
      text={
        bookmark.type === BookmarkType.EXTERNALURLBOOKMARK
          ? 'Delete Link'
          : 'Delete Highlight'
      }
      onClick={handleDeleteBookmark}
    />
  )

  const deleteNote = (
    <DropdownContextMenu.DropdownItem text="Delete Note" onClick={deleteNoteBody} />
  )

  const addNote = <DropdownContextMenu.DropdownItem text="Add Note" onClick={onAddNote} />

  const editLink = (
    <DropdownContextMenu.DropdownItem text="Edit Link" onClick={handleEditBookmark} />
  )

  const dropdownElement = (
    <div
      onClick={(e) => {
        e.stopPropagation()
      }}
      onKeyUp={onEnterKeyPress((e) => e.stopPropagation())}
      role="button"
      tabIndex={0}
    >
      <DropdownContextMenu
        positions={['bottom']}
        reposition={true}
        boundaryInset={54}
        dismissOnClick
        triggerElement={triggerElementOverride}
      >
        {bookmark.noteBody ? (
          <>
            {deleteNote}
            {!currentFolder && deleteBookmark}
            {bookmarkFolderDropdown()}
          </>
        ) : bookmark.type === BookmarkType.EXTERNALURLBOOKMARK ? (
          <>
            {editLink}
            {deleteBookmark}
          </>
        ) : (
          <>
            {ADD_NOTE_TYPES.includes(bookmark.type) && addNote}
            {!currentFolder && deleteBookmark}
            {bookmarkFolderDropdown()}
          </>
        )}
      </DropdownContextMenu>
    </div>
  )

  return dropdownElement
}

export default BookmarkControls
